<template>
  <div class="details question">
    <NavBar></NavBar>
    <div class="title-block">
      <button class="return" onclick="history.back()">
        <v-icon>mdi-keyboard-return</v-icon>
      </button>
      <h1 class="text-h2 my-8 text-center">Détails de la Question</h1>
    </div>
    <div class="mx-16 pb-10 pl-16">
      <v-card class="data-table">
        <v-card-title
          class="title-details rounded-lg text-center d-flex justify-center"
        >
          Informations
        </v-card-title>
        <div class="d-flex flex-row align-start background-details flex-wrap">
          <v-list class="details-content d-flex flex-wrap pt-0 pb-8 w-100">
            <div class="w-50_fix">
              <v-list-item
                class="w-100 pa-0"
                v-for="key in keyOrder1"
                :key="key"
              >
                <div class="details-table-infos px-10 py-3">
                  <div class="details-table-infos__cell">
                    <div>
                      <strong>{{ keyTextMap[key] }} :</strong>
                    </div>
                    <div class="details-table-infos-content">
                      {{ question[key] }}
                    </div>
                  </div>
                  <div
                    class="details-table-infos__edit"
                    @click="openUpdateDialog(key)"
                  >
                    <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                  </div>
                </div>
              </v-list-item>
            </div>

            <div class="w-50">
              <v-list-item
                class="w-100 pa-0"
                v-for="key in keyOrder2"
                :key="key"
              >
                <div class="details-table-infos px-10 py-3">
                  <div class="details-table-infos__cell">
                    <strong>{{ keyTextMap[key] }} :</strong>
                  </div>

                  <div class="checkbox">
                    <v-checkbox
                      v-model="question[key]"
                      @click="checkboxUpdate(key, !question[key])"
                    ></v-checkbox>
                  </div>
                </div>
              </v-list-item>

              <v-list-item
                class="w-100 pa-0"
                v-for="key in keyOrder3"
                :key="key"
              >
                <div class="details-table-infos px-10 py-3">
                  <div class="details-table-infos__cell">
                    <div :class="{ 'text-primary': key === 'Modules' }">
                      <strong>{{ keyTextMap[key] }} :</strong>
                    </div>
                    <div class="details-table-infos-content">
                      <div v-if="key === 'Modules'">
                        {{ question.Modules }}
                      </div>
                      <div
                        v-else-if="
                          key === 'Tags' && Array.isArray(question.Tags)
                        "
                      >
                        {{
                          question.Tags.map((tag) => tag.TXT_Tag).join(" / ")
                        }}
                      </div>
                      <div
                          v-else-if="
                          key === 'Equivalents' && Array.isArray(question.Equivalents)
                        "
                      >
                        <ul>
                          <li v-for="equivalent in question.Equivalents" :key="equivalent.ID_BT_EquivalentQuestion">
                           <a href="http://localhost">
                             {{ equivalent.ID_EquivalentQuestion.TXT_Question }}
                           </a>
                          </li>
                        </ul>
                      </div>
                      <template v-else>
                        {{ question[key] }}
                      </template>
                    </div>
                  </div>

                  <div
                    v-if="key !== 'Modules'"
                    class="details-table-infos__edit"
                    @click="openUpdateDialog(key)"
                  >
                    <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                  </div>
                </div>
              </v-list-item>
            </div>

            <div class="w-100">
              <h2 class="px-10 mt-6 text-h6 font-weight-bold">
                Commentaires :
                <v-btn icon class="ml-2" @click="openAddCommentDialog">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </h2>

              <div class="comments-list px-10 mt-4 d-flex">
                <v-card
                  v-for="comment in reversedComments"
                  :key="comment.ID_FS_CommentsQuestion"
                  class="comment-block pa-2 mb-4 rounded-lg"
                  color="secondary"
                >
                  <div class="comment-header d-flex justify-between">
                    <h3 class="comment-date mb-2 d-flex">
                      <span class="comment-date__user">
                        {{ comment.ID_NDAppUser }}
                      </span>
                      &nbsp;-&nbsp;
                      <span class="comment-date__date">
                        {{ formatDate(comment.DAT_Created) }}
                      </span>
                    </h3>
                    <div class="comment-actions">
                      <div
                        class="comment-edit"
                        @click="openCommentUpdateDialog(comment)"
                      >
                        <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                      </div>
                      <div
                        class="comment-delete"
                        @click="openDeleteDialog(comment)"
                      >
                        <v-icon icon="mdi-delete" size="x-large"></v-icon>
                      </div>
                    </div>
                  </div>
                  <p class="comment-text">{{ comment.TXT_Comment }}</p>
                </v-card>
              </div>
            </div>
          </v-list>
          <div
            class="d-flex w-100 mt-6 px-10 pb-8 align-center justify-space-between"
          >
            <div class="details-dates d-flex">
              <div
                class="w-100 pa-0 details-dates__element"
                v-for="key in keyOrder4"
                :key="key"
              >
                <div class="element">
                  <div>
                    <strong>{{ keyTextMap[key] }} :</strong>
                  </div>
                  <div>
                    <!-- Utilisation de la méthode formatDate si la clé est une date -->
                    {{
                      key === "DAT_LastEdited" || key === "DAT_Created"
                        ? formatDate(question[key])
                        : question[key]
                    }}
                  </div>
                </div>
              </div>
            </div>

            <v-btn color="primary" @click="openDeleteQuestionDialog(question)"
              >Supprimer la question</v-btn
            >
          </div>
        </div>
      </v-card>
    </div>

    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <v-textarea
            v-if="fieldToUpdate !== 'modules' && fieldToUpdate !== 'Tags' && fieldToUpdate !== 'Equivalents'"
            rows="1"
            auto-grow
            variant="underlined"
            v-model="newFieldValue"
            :rules="[rules.required]"
            color="primary"
          ></v-textarea>
        </v-card-text>
        <v-select
            v-if="fieldToUpdate === 'Equivalents'"
            v-model="selectedEquivalent"
            :items="availableQuestions"
            item-title="TXT_Question"
            item-value="ID_D_Question"
            label="Sélectionner une question"
            return-object
        ></v-select>

        <v-select
          v-if="fieldToUpdate === 'Tags'"
          v-model="newFieldValue"
          :items="Tags.map((Tags) => Tags.TXT_Tag)"
          label="Sélectionner des Tags"
          multiple
        ></v-select>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Annuler</v-btn>
          <v-btn color="primary" text @click="confirmUpdate">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addCommentDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">Ajouter un commentaire</v-card-title>
        <v-card-text>
          <v-textarea
            rows="1"
            auto-grow
            variant="underlined"
            v-model="newComment"
            :rules="[rules.required]"
            color="primary"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addCommentDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="addComment">Ajouter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">Supprimer le commentaire</v-card-title>
        <v-card-text
          >Voulez-vous vraiment supprimer ce commentaire ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="deleteComment">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteQuestionDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline"
          >Êtes-vous sûr de vouloir supprimer cette question ?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteQuestionDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="deleteQuestion">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import {
  base_url,
  questions_urls,
  comments_urls,
  user_urls,
  header,
} from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "QuestionSlug",
  components: {
    NavBar,
  },
  data() {
    return {
      question: {
        comments: [],
      },
      keyTextMap: {
        TXT_Question: "Question",
        TXT_RightAnswer: "Bonne réponse",
        TXT_WrongAnswer1: "Mauvaise répnse 1",
        TXT_WrongAnswer2: "Mauvaise réponse 2",
        TXT_Explication: "Explication",
        TXT_UpdatesToDo: "Modifications à faire",
        TXT_Mission: "Mission Expert",
        TXT_Feedback: "Avis",
        BIT_Active: "Actif ?",
        TXT_Ref: "REF",
        TXT_Notions: "Notions",
        BIT_Validate: "Validé ?",
        TXT_ExpertName: "Expert name ?",
        Modules: "Module associé",
        Tags: "Tags",
        Equivalents: "Questions Equivalent",

        DAT_Created: "Création",
        TXT_LastEditedBy: "Modifié par",
        DAT_LastEdited: "Dernière modification",
      },
      availableQuestions: [], // Questions available to add as equivalents
      selectedEquivalent: null,
      keyOrder1: [
        "TXT_Question",
        "TXT_RightAnswer",
        "TXT_WrongAnswer1",
        "TXT_WrongAnswer2",
        "TXT_Explication",
        "TXT_UpdatesToDo",
        "TXT_Mission",
        "TXT_Feedback",
      ],
      keyOrder2: ["BIT_Active", "BIT_Validate"],
      keyOrder3: ["TXT_ExpertName", "TXT_Ref", "TXT_Notions", "Modules", "Tags", "Equivalents"],
      keyOrder4: ["DAT_Created", "DAT_LastEdited", "TXT_LastEditedBy"],

      dialog: false,
      dialogTitle: "",
      newFieldValue: "",
      fieldToUpdate: null,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",

      currentCommentID: null,
      addCommentDialog: false,
      newComment: "",
      deleteDialog: false,
      commentToDelete: null,
      questionToDelete: null,
      deleteQuestionDialog: false,

      Modules: [],
      Tags: [],
      Equivalents: [],

      rules: {
        required: (value) => !!value || "Ce champ est requis.",
      },
    };
  },

  methods: {
    openDeleteQuestionDialog(question) {
      this.questionToDelete = question;
      this.deleteQuestionDialog = true;
    },
    fetchAvailableQuestions() {
      axios.get(`${base_url}${questions_urls.getQEquivalence}?exclude_id=${this.$route.params.id}`, header).then((response) => {
        this.availableQuestions = response.data;
        console.log(this.availableQuestions);
      });
    },
    addEquivalent() {
      const url = `${base_url}${questions_urls.addQEquivalence}`;
      const payload = {
        ID_D_Question: this.question.ID_D_Question,
        ID_EquivalentQuestion: this.selectedEquivalent.ID_D_Question,
      };
      axios
          .post(url, payload, header)
    },
    deleteEquivalent() {

    },



    async fetchUserDetails() {
      const userId = localStorage.getItem("id");
      if (userId) {
        try {
          const response = await this.getUserDetails(userId);
          this.userDetails = response.data;
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des détails de l'utilisateur:",
            error
          );
          this.snackbarMessage =
            "Erreur lors de la vérification des droits utilisateur.";
          this.snackbarColor = "error";
          this.snackbar = true;
        }
      } else {
        this.snackbarMessage =
          "Impossible de vérifier les droits utilisateur. Veuillez vous reconnecter.";
        this.snackbarColor = "error";
        this.snackbar = true;
      }
    },

    async deleteQuestion() {
      const questionId = this.$route.params.id;
      const url = `${base_url}${questions_urls.archive(questionId)}`;

      await this.fetchUserDetails();

      if (!this.userDetails?.is_superuser) {
        this.snackbarMessage =
          "Vous n'avez pas les droits nécessaires pour supprimer cette question.";
        this.snackbarColor = "error";
        this.snackbar = true;
        return;
      }

      if (this.questionToDelete) {
        const payload = {
          is_admin: true,
        };

        try {
          await axios.post(url, payload, header);
          this.snackbarMessage = `La Question a été supprimée.`;
          this.snackbar = true;
          const newCacheVersion = new Date().getTime().toString();
          localStorage.setItem("cacheVersion", newCacheVersion);
          this.$router.push("/questions");
        } catch (error) {
          console.error("Erreur lors de la suppression de la question:", error);
          this.snackbarMessage = `Erreur lors de la suppression de la <strong>Question</strong>`;
          this.snackbarColor = "error";
          this.snackbar = true;
        }
        this.deleteQuestionDialog = false;
      }
    },

    getUserDetails(id) {
      const url = `${base_url}${user_urls.get_details(id)}`;
      return axios.get(url, header);
    },

    getQuestionDetails() {
      const questionId = this.$route.params.id;
      const url = `${base_url}${questions_urls.get_details(questionId)}`;
      const commentsUrl = `${base_url}${comments_urls.question_all}`;

      axios
        .get(url, header)
        .then((response) => {
          this.question = {
            ...response.data,
          };
          if (this.question.TXT_LastEditedBy) {
            return this.getUserDetails(this.question.TXT_LastEditedBy);
          } else {
            return Promise.resolve(null);
          }
        })
        .then((userResponse) => {
          // Replace the user ID with username
          if (userResponse) {
            this.question.TXT_LastEditedBy = userResponse.data.username;
          }
          return axios.get(commentsUrl, header);
          // Fetch comments
        })
        .then((commentsResponse) => {
          this.question.comments = commentsResponse.data.filter(
            (comment) => comment.ID_D_Question === parseInt(questionId)
          );
          const uniqueUserIds = [
            ...new Set(
              this.question.comments.map((comment) => comment.ID_NDAppUser)
            ),
          ];
          return Promise.all(
            uniqueUserIds.map((userId) => this.getUserDetails(userId))
          );
        })
        .then((userResponses) => {
          userResponses.forEach((userResponse) => {
            const username = userResponse.data.username;
            this.question.comments.forEach((comment) => {
              if (comment.ID_NDAppUser === userResponse.data.id) {
                comment.ID_NDAppUser = username;
              }
            });
          });
        })
        .catch((error) => {
          console.error("Error fetching question details:", error);
        });
    },

    openAddCommentDialog() {
      this.newComment = ""; // Reset the new comment field
      this.addCommentDialog = true;
    },

    openUpdateDialog(key) {
      this.dialogTitle = `Modifier ${this.keyTextMap[key]}`;
      this.fieldToUpdate = key;
      if (key === "modules") {
        this.newFieldValue = this.question[key].map(
          (module) => module.ID_D_Module__TXT_Module
        );
      } else if (key === "Tags") {
        this.newFieldValue = this.question[key].map((Tags) => Tags.TXT_Tag);
      } else {
        this.newFieldValue = this.question[key];
      }
      this.dialog = true;
    },

    confirmUpdate() {
      if (this.newFieldValue) {
        if (this.fieldToUpdate === "modules") {
          const newModules = this.Modules.filter((module) =>
            this.newFieldValue.includes(module.ID_D_Module__TXT_Module)
          );
          this.updateQuestion(this.fieldToUpdate, newModules);
        } else if (this.fieldToUpdate === "TXT_Comment") {
          this.updateComment(this.currentCommentID, this.newFieldValue);
        } else {
          this.updateQuestion(this.fieldToUpdate, this.newFieldValue);
        }
        this.dialog = false;
      }
    },
    checkboxUpdate(key, newValue) {
      this.updateQuestion(key, newValue);
    },

    updateComment(commentId, newComment) {
      const url = `${base_url}${comments_urls.question_details(commentId)}`;

      axios
        .put(url, { TXT_Comment: newComment }, header)
        .then(() => {
          const commentIndex = this.question.comments.findIndex(
            (comment) => comment.ID_FS_CommentsQuestion === commentId
          );
          this.question.comments[commentIndex].TXT_Comment = newComment;

          const newCacheVersion = new Date().getTime().toString();
          localStorage.setItem("cacheVersion", newCacheVersion);

          this.snackbarMessage = `Le commentaire a été mis à jour.`;
          this.snackbarColor = "success";
          this.snackbar = true;
        })
        .catch((error) => {
          console.error("Error updating comment:", error);

          this.snackbarMessage = `Une erreur s'est produite lors de la mise à jour du <strong>Commentaire</strong>`;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },

    updateQuestion(field, newValue) {
      // Update the question on the server
      const questionId = this.$route.params.id;
      const url = `${base_url}${questions_urls.update(questionId)}`;

      const updatedData = {
        TXT_Question: this.question.TXT_Question,
        TXT_RightAnswer: this.question.TXT_RightAnswer,
        TXT_WrongAnswer1: this.question.TXT_WrongAnswer1,
        TXT_WrongAnswer2: this.question.TXT_WrongAnswer2,
        TXT_Explication: this.question.TXT_Explication,
        TXT_Mission: this.question.TXT_Mission,
        TXT_Feedback: this.question.TXT_Feedback,
        TXT_UpdatesToDo: this.question.TXT_UpdatesToDo,
        BIT_Active: this.question.BIT_Active,
        TXT_Ref: this.question.TXT_Ref,
        TXT_Notions: this.question.TXT_Notions,
        TXT_ExpertName: this.question.TXT_ExpertName,

        DAT_Created: this.question.DAT_Created,
        [field]: newValue, // Override the field to update with the new value
        DAT_LastEdited: new Date().toISOString(), // Current date
        TXT_CreatedBy: this.question.TXT_CreatedBy, // Created by field should not change
        TXT_LastEditedBy: this.$store.state.auth.id, // Current user ID from Vuex store

        modules: this.question.modules,
        Tags: this.question.Tags,
      };

      axios
        .put(url, updatedData, header)
        .then((response) => {
          this.question = response.data;

          this.getQuestionDetails();

          const newCacheVersion = new Date().getTime().toString();
          localStorage.setItem("cacheVersion", newCacheVersion);

          this.snackbarMessage = `Le champ <strong>${this.keyTextMap[field]}</strong> a été mis à jour`;
          this.snackbarColor = "success";
          this.snackbar = true;
        })
        .catch((error) => {
          console.error(error);

          this.snackbarMessage = `Une erreur s'est produite lors de la mise à jour du champ <strong>${this.keyTextMap[field]}</strong>`;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },

    formatDate(date) {
      const dateObject = new Date(date);
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      const formattedDate = dateObject.toLocaleDateString("fr-FR", options);
      return formattedDate;
    },

    openCommentUpdateDialog(comment) {
      this.dialogTitle = `Modifier le commentaire de ${this.formatDate(
        comment.DAT_Created
      )}`;
      this.fieldToUpdate = "TXT_Comment";
      this.newFieldValue = comment.TXT_Comment;
      this.dialog = true;
      this.currentCommentID = comment.ID_FS_CommentsQuestion; // Stocker l'ID du commentaire actuel
    },

    addComment() {
      const url = `${base_url}${comments_urls.question_all}`;

      const commentData = {
        ID_NDAppUser: this.$store.state.auth.id,
        DAT_Created: new Date().toISOString(),
        TXT_Comment: this.newComment,
        ID_D_Question: this.$route.params.id,
      };

      axios
        .post(url, commentData, header)
        .then(() => {
          this.getQuestionDetails(); // Fetch subtheme details again to update the comments list

          const newCacheVersion = new Date().getTime().toString();
          localStorage.setItem("cacheVersion", newCacheVersion);

          this.snackbarMessage = `Votre commentaire a été ajouté avec succès.`;
          this.snackbar = true;

          this.addCommentDialog = false; // Close the dialog
        })
        .catch((error) => {
          console.error("Error adding comment:", error);

          this.snackbarMessage = `Une erreur s'est produite lors de l'ajout du commentaire`;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },
    openDeleteDialog(comment) {
      this.commentToDelete = comment;
      this.deleteDialog = true;
    },

    deleteComment() {
      if (this.commentToDelete) {
        const url = `${base_url}${comments_urls.delete_question(
          this.commentToDelete.ID_FS_CommentsQuestion
        )}`;

        axios
          .delete(url, header)
          .then(() => {
            this.getQuestionDetails();

            const newCacheVersion = new Date().getTime().toString();
            localStorage.setItem("cacheVersion", newCacheVersion);

            this.snackbarMessage = `Votre commentaire a été supprimé.`;
            this.snackbar = true;

            this.deleteDialog = false;
          })
          .catch((error) => {
            console.error("Error deleting comment:", error);

            this.snackbarMessage = `Erreur lors de la suppression du commentaire`;
            this.snackbarColor = "error";
            this.snackbar = true;
          });
      }
    },
  },

  computed: {
    reversedComments() {
      return [...(this.question.comments || [])].reverse();
    },
  },

  created() {
    this.getQuestionDetails();
  },

  mounted() {
    document.title = "Question - Détails";
    this.fetchAvailableQuestions();
  },
};
</script>

<style lang="scss">
.details.question .data-table .v-table .v-data-table__td {
  width: 100%;
}

.w-50_fix {
  width: calc(50% - 1px);
  margin-right: 1px;
}
</style>
