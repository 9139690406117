<template>
  <div class="details management">
    <NavBar></NavBar>
    <div class="title-block">
      <button class="return" onclick="history.back()">
        <v-icon>mdi-keyboard-return</v-icon>
      </button>
      <h1 class="text-h2 my-8 text-center">Détails du Management</h1>
    </div>
    <div class="mx-16 pb-10 pl-16">
      <v-card class="data-table">
        <v-card-title
          class="title-details rounded-lg text-center d-flex justify-center"
        >
          Informations
        </v-card-title>
        <div class="d-flex flex-row align-start background-details flex-wrap">
          <v-list class="details-content d-flex flex-wrap pt-0 pb-8 w-100">
            <div class="w-50_fix">
              <v-list-item
                class="w-100 pa-0"
                v-for="key in keyOrder1"
                :key="key"
              >
                <div class="details-table-infos px-10 py-3">
                  <div class="details-table-infos__cell">
                    <div>
                      <strong>{{ keyTextMap[key] }} :</strong>
                    </div>
                    <div>
                      {{ management[key] }}
                    </div>
                  </div>
                  <div
                    class="details-table-infos__edit"
                    @click="openUpdateDialog(key)"
                  >
                    <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                  </div>
                </div>
              </v-list-item>
            </div>
            <div class="w-50">
              <v-list-item
                class="w-100 pa-0"
                v-for="key in keyOrder2"
                :key="key"
              >
                <div class="details-table-infos px-10 py-3">
                  <div class="d-flex align-center justify-space-between w-100">
                    <div class="details-table-infos-content">
                      <strong>{{ keyTextMap[key] }} :</strong>
                    </div>
                    <div class="checkbox">
                      <v-checkbox
                        v-model="management[key]"
                        @click="checkboxUpdate(key, !management[key])"
                      ></v-checkbox>
                    </div>
                  </div>
                </div>
              </v-list-item>

              <div class="businessunits v-list-item__content px-10 py-3">
                <div class="d-flex justify-space-between align-center">
                  <h4 class="mb-2">
                    <strong>BusinessUnits :</strong>
                  </h4>
                  <div
                    class="details-table-infos__edit"
                    @click="openUpdateDialog('BusinessUnit')"
                  >
                    <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                  </div>
                </div>

                <div v-if="management && management.BusinessUnit">
                  <ul
                    v-for="unit in management.BusinessUnit"
                    :key="unit.ID_D_BusinessUnit"
                  >
                    <li
                      class="businessunits"
                      v-for="key in keyOrder3"
                      :key="key"
                    >
                      {{ unit[key] }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="px-10 py-3 mt-8 d-flex align-center">
              <h2 class="text-h6 font-weight-bold">
                Apprenant·es associé·es :
              </h2>
              <v-btn
                icon
                class="details-table-infos__edit ml-2"
                @click="openUpdateDialog('Trainees')"
              >
                <v-icon icon="mdi-pencil" size="x-large"></v-icon>
              </v-btn>
            </div>
            <v-data-table
              :headers="TraineesHeaders"
              :items="management.Trainees"
              class="w-100 related-data-table mx-10"
              color="table"
            >
              <template v-slot:[`item.TXT_Title`]="{ item }">
                <router-link :to="`/trainee/${item.ID_D_Trainee}`">{{
                  item.TXT_Module
                }}</router-link>
              </template>
            </v-data-table>
          </v-list>
        </div>
      </v-card>
    </div>

    <v-dialog
      v-if="fieldToUpdate === 'Trainees'"
      v-model="dialog"
      transition="dialog-top-transition"
      persistent
      min-width="800"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="search"
            clearable
            prepend-inner-icon="mdi-magnify"
            label="Rechercher..."
            single-line
            hide-details
            @keyup.enter="getTrainees(true)"
            @click:prepend-inner="getTrainees(true)"
            @click:clear="resetSearch"
            density="compact"
            bg-color="grey-darken-3"
            color="primary"
          ></v-text-field>
          <v-data-table-server
            :show-current-page="true"
            :headers="DialogTraineesHeaders"
            v-model:page="currentPage"
            :items="PopupTrainees"
            :items-length="totalTrainees"
            items-per-page-text="Nombre d'élements par page."
            :loading="isLoading"
            no-data-text="Aucune donnée"
            @update:options="updateOptions"
            item-key="ID_D_Trainee"
            density="compact"
          >
            <template v-slot:[`item.selected`]="{ item }">
              <v-checkbox
                class="d-flex justify-center ma-0 pa-0"
                v-model="item.selected"
                @change="updateSelectedItems(item)"
                density="compact"
              ></v-checkbox>
            </template>
          </v-data-table-server>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Annuler</v-btn>
          <v-btn color="primary" text @click="confirmUpdate">Valider</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="fieldToUpdate === 'BusinessUnit'"
      v-model="dialog"
      transition="dialog-top-transition"
      persistent
      min-width="800"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="search"
            clearable
            prepend-inner-icon="mdi-magnify"
            label="Rechercher..."
            single-line
            hide-details
            @keyup.enter="getBusinessUnits(true)"
            @click:prepend-inner="getBusinessUnits(true)"
            @click:clear="resetSearch"
            density="compact"
            bg-color="grey-darken-3"
            color="primary"
          ></v-text-field>
          <v-data-table-server
            :show-current-page="true"
            :headers="buHeaders"
            v-model:page="currentPage"
            :items="BusinessUnits"
            :items-length="totalBusinessUnits"
            items-per-page-text="Nombre d'élements par page."
            :loading="isLoading"
            no-data-text="Aucune donnée"
            @update:options="updateOptions"
            item-key="ID_D_BusinessUnit"
            density="compact"
          >
            <template v-slot:[`item.selected`]="{ item }">
              <v-checkbox
                class="d-flex justify-center ma-0 pa-0"
                :value="item.ID_D_BusinessUnit"
                v-model="selectedBusinessUnitId"
                @change="updateSelectedBusinessUnit(item)"
                density="compact"
              ></v-checkbox>
            </template>
          </v-data-table-server>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Annuler</v-btn>
          <v-btn color="primary" text @click="confirmUpdate">Valider</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="fieldToUpdate !== 'Trainees' && fieldToUpdate !== 'BusinessUnit'"
      v-model="dialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <v-textarea
            rows="1"
            auto-grow
            variant="underlined"
            v-model="newFieldValue"
            :rules="[rules.required]"
            color="primary"
          ></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Annuler</v-btn>
          <v-btn color="primary" text @click="confirmUpdate">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import {
  base_url,
  managements_urls,
  bu_urls,
  trainees_urls,
  header,
} from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "ManangementSlug",
  components: {
    NavBar,
  },
  data() {
    return {
      keyTextMap: {
        TXT_LastName: "Nom",
        TXT_FirstName: "Prénom",
        TXT_Email: "Email",
        TXT_PhoneNumber: "Numéro de téléphone",
        Trainees: "Apprenant·es associé·es",
        BIT_ReportReceiver: "Réception de reporting",
        BIT_IsTrainingDepartment: "Services de formation",
        BIT_ReportReceiverChildBU: "Visibilité sur les BU enfants",
        ID_D_BusinessUnit: "Business Unit",
      },
      keyOrder1: [
        "TXT_LastName",
        "TXT_FirstName",
        "TXT_Email",
        "TXT_PhoneNumber",
      ],
      keyOrder2: [
        "BIT_ReportReceiver",
        "BIT_IsTrainingDepartment",
        "BIT_ReportReceiverChildBU",
      ],
      keyOrder3: [
        "TXT_BusinessUnitLevel1",
        "TXT_BusinessUnitLevel2",
        "TXT_BusinessUnitLevel3",
        "TXT_BusinessUnitLevel4",
        "TXT_BusinessUnitLevel5",
      ],
      TraineesHeaders: [
        { title: "Nom", value: "TXT_LastName", key: "TXT_LastName" },
        { title: "Prénom", value: "TXT_FirstName", key: "TXT_FirstName" },
        { title: "E-Mail", value: "TXT_Email", key: "TXT_Email" },
      ],
      DialogTraineesHeaders: [
        {
          title: "Sélectionner",
          value: "selected",
          key: "selected",
          sortable: false,
          width: "100px",
        },
        {
          title: "Nom",
          value: "TXT_LastName",
          key: "TXT_LastName",
          sortable: false,
        },
        {
          title: "Prénom",
          value: "TXT_FirstName",
          key: "TXT_FirstName",
          sortable: false,
        },
        {
          title: "E-Mail",
          value: "TXT_Email",
          key: "TXT_Email",
          sortable: false,
        },
      ],
      buHeaders: [
        {
          title: "Sélectionner",
          value: "selected",
          key: "selected",
          sortable: false,
          width: "100px",
        },
        {
          title: "BU Niveau 1",
          value: "TXT_BusinessUnitLevel1",
          key: "TXT_BusinessUnitLevel1",
        },
        {
          title: "BU Niveau 2",
          value: "TXT_BusinessUnitLevel2",
          key: "TXT_BusinessUnitLevel2",
        },
        {
          title: "BU Niveau 3",
          value: "TXT_BusinessUnitLevel3",
          key: "TXT_BusinessUnitLevel3",
        },
        {
          title: "BU Niveau 4",
          value: "TXT_BusinessUnitLevel4",
          key: "TXT_BusinessUnitLevel4",
        },
        {
          title: "BU Niveau 5",
          value: "TXT_BusinessUnitLevel5",
          key: "TXT_BusinessUnitLevel5",
        },
      ],
      selectedBusinessUnitId: null,
      initialBusinessUnitId: null,
      totalTrainees: 0,
      totalBusinessUnits: 0,
      currentPage: 1,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      PopupTrainees: [],
      BusinessUnits: [],
      selectedItems: new Set(),
      selectedBusinessUnits: null,
      dialog: false,
      dialogTitle: "",
      newFieldValue: "",
      fieldToUpdate: null,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",
      management: [],
      search: "",
      isLoading: false,
      rules: {
        required: (value) => !!value || "Ce champ est requis.",
      },
    };
  },

  methods: {
    async initializeCacheBusting() {
      let cacheVersion = localStorage.getItem("cacheVersion");
      if (!cacheVersion) {
        const response = await axios.get(
          `${base_url}${trainees_urls.get_all}`,
          header
        );
        cacheVersion = response.data.results.cache_version;
        localStorage.setItem("cacheVersion", cacheVersion);
      }
      return cacheVersion;
    },

    updateCacheBusting(newCacheVersion) {
      localStorage.setItem("cacheVersion", newCacheVersion);
    },

    async checkForUpdates() {
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${
        trainees_urls.get_all
      }?page=${page}&page_size=${itemsPerPage}&timestamp=${new Date().getTime()}`;
      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }
      try {
        const response = await axios.get(url, header);
        if (
          response.data.results.cache_version !==
          localStorage.getItem("cacheVersion")
        ) {
          this.updateCacheBusting(response.data.results.cache_version);
          await this.getTrainees();
        }
      } catch (error) {
        console.error("Error checking for updates:", error);
      }
    },

    resetSearch() {
      this.search = "";
      if (this.fieldToUpdate === "BusinessUnit") {
        this.getBusinessUnits(true);
      } else {
        this.getTrainees(true);
      }
    },

    updateSelectedItems(item) {
      if (item.selected) {
        this.selectedItems.add(item.ID_D_Trainee);
      } else {
        this.selectedItems.delete(item.ID_D_Trainee);
      }
    },

    updateSelectedBusinessUnit(item) {
      this.selectedBusinessUnitId = item.ID_D_BusinessUnit;
      this.BusinessUnits.forEach((bu) => {
        bu.selected = bu.ID_D_BusinessUnit === this.selectedBusinessUnitId;
      });
    },

    updateOptions(newOptions) {
      this.options = newOptions;
      if (this.fieldToUpdate === "BusinessUnit") {
        this.getBusinessUnits();
      } else {
        this.getTrainees();
      }
    },

    getManagementDetails() {
      const managementId = this.$route.params.id;
      const url = `${base_url}${managements_urls.get_details(managementId)}`;

      axios
        .get(url, header)
        .then((response) => {
          this.management = {
            ...response.data,
          };
          if (this.management.ID_D_BusinessUnit) {
            this.selectedBusinessUnitId = this.management.ID_D_BusinessUnit;
            this.initialBusinessUnitId = this.management.ID_D_BusinessUnit;
          }
        })
        .catch((error) => {
          console.error("Error fetching management details:", error);
        });
    },

    async getBusinessUnits(resetPage = false) {
      if (resetPage) {
        this.currentPage = 1;
        this.options.page = 1;
      }

      this.isLoading = true;
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${bu_urls.get_all}?page=${page}&page_size=${itemsPerPage}`;
      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }
      try {
        const response = await axios.get(url, header);
        this.totalBusinessUnits = response.data.count;
        this.BusinessUnits = response.data.results.results.map((bu) => ({
          ...bu,
          selected: bu.ID_D_BusinessUnit === this.selectedBusinessUnitId,
        }));
        this.currentPage = page;
      } catch (error) {
        console.error("Error fetching Business units:", error);
      } finally {
        this.isLoading = false;
      }
    },

    async getTrainees(resetPage = false) {
      if (resetPage) {
        this.currentPage = 1;
        this.options.page = 1;
      }

      this.isLoading = true;
      const cacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${trainees_urls.get_all}?page=${page}&page_size=${itemsPerPage}&cacheVersion=${cacheVersion}`;
      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }
      try {
        const response = await axios.get(url, header);
        this.totalTrainees = response.data.count;
        this.PopupTrainees = response.data.results.results.map((trainee) => ({
          ...trainee,
          selected: this.selectedItems.has(trainee.ID_D_Trainee),
        }));
        this.currentPage = page;
      } catch (error) {
        console.error("Error fetching trainees:", error);
      } finally {
        this.isLoading = false;
      }
      this.checkForUpdates();
    },

    openUpdateDialog(key) {
      this.dialogTitle = `Modifier ${this.keyTextMap[key]}`;
      this.fieldToUpdate = key;
      if (key === "BusinessUnit") {
        this.selectedBusinessUnitId = this.initialBusinessUnitId;
        this.getBusinessUnits();
        this.dialogTitle = "Modifier la Business Unit";
      } else if (key === "Trainees") {
        this.selectedItems = new Set(
          this.management.Trainees.map((trainee) => trainee.ID_D_Trainee)
        );
        this.getTrainees();
      } else {
        this.newFieldValue = this.management[key];
      }
      this.dialog = true;
    },

    confirmUpdate() {
      if (this.fieldToUpdate === "Trainees") {
        this.newFieldValue = Array.from(this.selectedItems);
        this.updateManagement(this.fieldToUpdate, this.newFieldValue);
      } else if (this.fieldToUpdate === "BusinessUnit") {
        this.updateManagement("ID_D_BusinessUnit", this.selectedBusinessUnitId);
      } else if (
        this.newFieldValue !== null &&
        this.newFieldValue !== undefined
      ) {
        this.updateManagement(this.fieldToUpdate, this.newFieldValue);
      }
      this.dialog = false;
    },

    checkboxUpdate(key, newValue) {
      this.updateManagement(key, newValue);
    },

    updateManagement(field, newValue) {
      const managementId = this.$route.params.id;
      const url = `${base_url}${managements_urls.update(managementId)}`;

      const updatedData = {
        TXT_LastName: this.management.TXT_LastName,
        TXT_FirstName: this.management.TXT_FirstName,
        TXT_Email: this.management.TXT_Email,
        TXT_PhoneNumber: this.management.TXT_PhoneNumber,
        BIT_ReportReceiver: this.management.BIT_ReportReceiver,
        BIT_IsTrainingDepartment: this.management.BIT_IsTrainingDepartment,
        BIT_ReportReceiverChildBU: this.management.BIT_ReportReceiverChildBU,
        [field]: newValue,
      };

      if (field !== "Trainees") {
        updatedData.Trainees = this.management.Trainees.map(
          (trainee) => trainee.ID_D_Trainee
        );
      }

      if (field === "Trainees") {
        updatedData.Trainees = this.newFieldValue;
      }

      if (field === "ID_D_BusinessUnit" && newValue === null) {
        updatedData[field] = this.initialBusinessUnitId;
      }

      axios
        .put(url, updatedData, header)
        .then((response) => {
          this.management = response.data;
          this.initialBusinessUnitId = this.management.ID_D_BusinessUnit;
          this.selectedBusinessUnitId = this.initialBusinessUnitId;

          this.getManagementDetails();

          this.snackbarMessage = `Le champ <strong>${this.keyTextMap[field]}</strong> a été mis à jour`;
          this.snackbarColor = "success";
          this.snackbar = true;

          const newCacheVersion = new Date().getTime().toString();
          localStorage.setItem("cacheVersion", newCacheVersion);
        })
        .catch((error) => {
          console.error(error);

          this.snackbarMessage = `Une erreur s'est produite lors de la mise à jour du champ <strong>${this.keyTextMap[field]}</strong>`;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },

    formatBusinessUnitTitle(levels) {
      const title = levels
        .filter(Boolean)
        .map((item, index) => `${index + 1}.${item}`)
        .join(" - ");
      return title.length > 75 ? title.substring(0, 75) + "..." : title;
    },
  },

  watch: {
    dialog(newVal) {
      if (newVal && this.fieldToUpdate === "Trainees") {
        this.getTrainees();
      } else if (newVal && this.fieldToUpdate === "BusinessUnit") {
        this.getBusinessUnits();
      }
    },
    PopupTrainees: {
      handler(newItems) {
        newItems.forEach((item) => {
          if (item.selected) {
            this.selectedItems.add(item.ID_D_Trainee);
          } else {
            this.selectedItems.delete(item.ID_D_Trainee);
          }
        });
      },
      deep: true,
    },
    BusinessUnits: {
      handler(newItems) {
        const selectedBU = newItems.find((item) => item.selected);
        if (selectedBU) {
          this.selectedBusinessUnitId = selectedBU.ID_D_BusinessUnit;
        } else {
          this.selectedBusinessUnitId = this.initialBusinessUnitId;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.fieldToUpdate === "Trainees") {
          this.getTrainees();
        } else if (this.fieldToUpdate === "BusinessUnit") {
          this.getBusinessUnits();
        }
      },
      deep: true,
    },
  },

  created() {
    this.getManagementDetails();
    this.getTrainees();
  },

  mounted() {
    document.title = "Management - Détails";
  },
};
</script>

<style lang="scss">
.details.management .data-table .v-table .v-data-table__td {
  width: 100%;
}

.w-50_fix {
  width: calc(50% - 1px);
  margin-right: 1px;
}
</style>
